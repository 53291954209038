import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './../../Header/Header';
import Footer from './../../Footer/Footer';
import Gettouch from './../../Gettouch.js';
import './home.css';

const Home = () => {
  const [posts , setposts] = useState([]);
  useEffect(() =>{
    let url = 'http://localhost/webcubator-react/wserver/wp-json/wp/v2/pages';
    axios.get(url).then((response) =>{
    // console.log(response.data);
    setposts(response.data);
    
    })
    .catch( err => console.log(err));
    
  }, []);
  //console.log('posts', posts);
  return (
    <>
    <div className='page home'>
    <Header/>
     {<Gettouch/>}
      {
        posts.map((post) =>{
          return (          
            <div key ={post.id} dangerouslySetInnerHTML= {{__html:post.content.rendered}} />
          )
        })
      }
      <Footer/>
      </div>
    </>
  )
}

export default Home 