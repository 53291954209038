import React, {useState} from "react";
import { Link } from "react-router-dom";

import './header.css';

import logo from './../../images/webcubator-logo.webp';


 

export default function Header(props) {

  const [navbarOpen, setNavbarOpen] = useState(false);
  
    
    return( 
      <header className={navbarOpen ? 'show-menu' : ''} >
          <div className="wcontainer">
            <div className="dflex"> 
            <div className="left-header">
              <div className="logo-sitename">
                <Link to="/" className="site-logo"><img src={logo} width="200" alt="logo"/></Link>            
                <div className="sitename-slogan">
                  <Link className="sitename" to="/">Webcubator Technologies</Link>
                  <p>Web incubation, Simplified</p>
                </div>
                
                
            </div>
          </div>         
          <div className="right-header">
          
            <button type="button" className="w-navbar-toggle" 
              onClick={()=>setNavbarOpen((pre) => !pre)}
            >
              
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div className="w-custom-header">
             <span className="close-navbar-toggle" onClick={()=>setNavbarOpen((pre) => !pre)}></span>
              <div className="w-menu-wrapper">
              <div className="main-navigation" id="mainmenu">
              <ul className="main-nav">
                <li className="nav-item" linkclass="home">
                  <Link to="/" className="nav-link">Home</Link>
                </li>               
                <li className="nav-item" linkclass="services">
                  <Link to="/services" className="nav-link">Services</Link>
                </li>
                <li className="nav-item" linkclass="work">
                  <Link to="/work" className="nav-link">Work</Link>
                </li>                
                <li className="nav-item" linkclass="about">
                  <Link to="/about" className="nav-link">About</Link>
                </li>
                <li className="nav-item" linkclass="blog">
                  <Link to="/blog" className="nav-link">Blog</Link>
                </li>
                <li className="nav-item" linkclass="contact">
                  <Link to="/contact" className="nav-link">Contact</Link>
                </li>               
              </ul>      
            </div>
            </div>
            </div>
          </div> 
        </div></div>    

            
      </header>
       
    );
}