import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import facebook from '../../images/fb.webp';
import gplus from '../../images/gmail.webp';
import insta from '../../images/insta.webp';
import twitter from '../../images/twiter.webp';
import linkedin from '../../images/linkedin.webp';
// import Gototop from './scrolltotop';

const Footer = () => {
  return (
    <footer id='footer'>
      <div className='wrapper'>
      <div className='cols'>
      <section className='col col1'>
        <h3 className='section-title'>Company</h3>
        <div className='section-links'>
          <ul className='lists'>
            <li>
              <Link to="/about" className='aboutus'>About Us</Link>
            </li>
            <li>
              <Link to="/blog" className='blog'>Blog</Link>
            </li>
            <li>
              <Link to="/contact" className='contact'>Contact Us</Link>
            </li>
          </ul>
        </div>
      </section>
      <section className='col col2'>
        <h3 className='section-title'>Our Services</h3>
        <div className='section-links'>
          <ul className='lists'>
            <li>
              <Link to="/services/digital-marketing" className='digi'>Digital Marketing Solutions</Link>
            </li>
            <li>
              <Link to="/services/machine-learning" className='ml'>Machine Learning/Artificial Intelligence Solutions</Link>
            </li>
            <li>
              <Link to="/services/web-application" className='webapp'>Web/Mobile Applications</Link>
            </li>
            <li>
              <Link to="/services/new-frontiers" className='newfr'>New Frontiers</Link>
            </li>
          </ul>
        </div>
      </section>
      <section className='col col3'>
        <h3 className='section-title'>Address</h3>
        <div className='section-links'>
          <h5>Webcubator Technologies</h5>
          <p>Kalyani Nagar<br/>Pune Maharashtra 411014<br/>India</p>
        </div>
      </section>
      <section className='col col4'>
        <div className='last-col-wrap'>
        <div className='sub-col'>
        <h3 className='section-title'>Quick Contact</h3>
        <div className='section-links'>
          <ul className='lists'>
            <li className='phone'>
              <Link to="tel:+91 9923673679">+91 9923673679</Link>
            </li>
            <li className='email'>
              <Link to="mailto:contact@webcubator.co">contact@webcubator.co</Link>
            </li>
          </ul>
        </div>
        </div>
        <div className='sub-col'>
        <h3 className='section-title second'>Connect with us</h3>
        <div className='section-links social'>
          <ul>
            <li>
              <Link to="https://www.facebook.com/webcubatortechnologies/" target='_blank' title='facebook' ><img src={facebook} alt='facebook'/></Link>
            </li>
            <li>
              <Link to="https://plus.google.com/" target='_blank' title='Google Plus' ><img src={gplus} alt='Google Plus'/></Link>
            </li>
            <li>
              <Link to="https://twitter.com/WebcubatorTech" target='_blank' title='Twitter' ><img src={twitter} alt='Twitter'/></Link>
            </li>
            <li>
              <Link to="https://www.linkedin.com/company/webcubator-technologies/" target='_blank' title='Linkedin' ><img src={linkedin} alt='Linkedin'/></Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/webcubatortech/" target='_blank' title='Instagram' ><img src={insta} alt='Instagram'/></Link>
            </li>
          </ul>
        </div>
        </div>
        </div>
      </section>
      </div>
      </div>
      <div className='copyright-txt'><p>© 2023 Webcubator Technologies. All rights reserved.</p></div>
      {/* <Gototop /> */}
    </footer>
  )
}

export default Footer