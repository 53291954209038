import React, { useEffect, useState } from "react";

const Gettouch = () => {
    const [isgvisible , gsetisvisible] = useState(false)
    const getouch =() => {

        window.scrollTo({top:0, left:0, behavior:"smooth"});

    };

    const Glistenscroll = () =>
    {

        

        let Gheighttohide = 100;
        const gscrennscroll = document.body.scrollTop || document.documentElement.scrollTop;

        if(gscrennscroll > Gheighttohide)
        {
            gsetisvisible(true);
        }
        else
        {
            gsetisvisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll",Glistenscroll)
        return () => window.removeEventListener("scroll",Glistenscroll)
    },[]);




    return (
    <>
    {isgvisible && (

        <div className="get-touch" onClick={getouch}>
        <ul class="dummy-block">
        <li class="contact">
        <a href="/contact">Get in Touch</a>
        </li>
        </ul>
        </div>


    )}
    
    </>
    );
};


export default Gettouch;