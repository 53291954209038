import { Helmet } from 'react-helmet';
import Home from './Components/Pages/Home/Home';
import { Route, Routes } from 'react-router-dom';

import './App.css';

function App() {
  // useEffect( () =>{
  //   console.log("from App js");
  // })
  return (
    <>
     <Helmet>
        <title>Technology incubators for Web, Mobile and Machine Learning - Webcubator</title>
        <meta name="description" content="Webcubator is your technology incubator for Web, Mobile & Machine Learning Applications. A team of experienced and professional web ninjas."/>
        <meta property="og:site_name" content="Webcubator Technologies" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://webcubator.co/" />
        <meta property="og:title" content="Webcubator Technologies" />
        <meta property="og:description" content="Web incubation, Simplified" />
     </Helmet>
     <Routes>
        <Route path='/' element={ <Home/> } />
        {/* <Route path='/services' element={ <Services/> } /> */}
     </Routes>
     
     
    </>
  );
}

export default App;
